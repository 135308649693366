.formGroupContainer {
  position: relative;
}

.toggleButton {
  position: absolute;
  right: 20px;
  top: 28px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 2px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
}

.toggleIcon {
  width: 20px;
  height: 20px;
}
