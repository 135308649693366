@import '../../styles/global/variables';

.header {
  align-self: stretch;
  margin-right: 30px;
  font-family: $font-title;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -1.28px;

  color: black;
}

.groupTitle {
  margin-top: 32px;
  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

.groupSubtitle {
  margin-top: 16px;
  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.formGroup {
  display: flex;
  flex-direction: column;
}

.main :global(.form-field) input {
  display: flex;
  height: 56px;
  padding: 16px 24px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  box-sizing: border-box;

  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;

  border-radius: 100px;
  border: 1px solid #bebebb;
  background: #ffffff;
}

.main :global(.form-field) input::placeholder {
  color: #9e9e9c;
}

.main :global(.form-field__error) {
  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

.stateAndZipFields {
  display: flex;
  flex-direction: row;
  align-items: flex-start !important;

  :global(.form-group) {
    margin-top: 0;
  }
}

.stateField {
  :global(.react-select-container) {
    min-width: 280px;
    top: 0px;
    font-family: $font-default;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }

  :global(.react-select-container) input {
    font-family: $font-default;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }

  :global(.react-select__placeholder) {
    color: hsl(0, 0, 70%);
  }

  :global(.react-select__control) {
    padding-left: 14px;
    height: 56px;
    border-radius: 100px;
    border: 1px solid #bebebb;
    background: #ffffff;
    cursor: pointer;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
}

.shippingCheckbox {
  display: flex;
  align-items: center;
  margin-top: 16px;

  input {
    width: 24px;
    height: 24px;
    appearance: none;
    background-color: white;
    border: 2px solid black;
    border-radius: 3px;
    cursor: pointer;
  }

  input:checked {
    background-color: white;
    border: 2px solid black;
    position: relative;
  }

  input:checked::before {
    content: '';
    position: absolute;
    width: 5px;
    height: 10px;
    border: solid black;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    left: 7px;
    top: 2px;
  }

  span {
    margin-left: 8px;
    color: #000;
    text-align: center;
    font-family: $font-default;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    cursor: pointer;
  }
}

.submitButton {
  display: flex;
  height: 55px;
  padding: 18px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  text-align: center;
  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.16px;

  border-radius: 100px;
  background: black;
  color: white;
}

.submitButton:disabled {
  opacity: 0.5;
}

.submitButton:disabled::after {
  content: '';
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-top: 3px solid white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  position: absolute;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.multiSelectApplicationContainer {
  display: flex;
  flex-direction: column;
}

.multiSelectContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  border-radius: 30px;
  border: 1px solid #ccc;
  overflow: hidden;
}

.multiSelectOption {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
  padding: 10px 26px 10px 10px;
  height: 56px;

  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;

  border: none;
  background-color: white;
  cursor: pointer;

  @media (max-width: 600px) {
    font-size: 12px;
  }
}

.multiSelectOption:not(:last-child) {
  border-right: 1px solid #ccc;
}

.multiSelectOptionSelected {
  background-color: #f3f3f2;
}

.multiSelectImage {
  height: 16px;
  width: 16px;
  margin-right: 4px;
  visibility: hidden;
}

.multiSelectImageSelected {
  visibility: visible;
}

.successContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.successTitle {
  margin-bottom: 8px;
  align-self: stretch;
  text-align: center;
  font-family: $font-title;
  font-size: 80px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -1.6px;
  @media (max-width: 600px) {
    font-size: 48px;
  }
}

.successSubtitle {
  align-self: stretch;
  text-align: center;
  font-family: $font-default;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  @media (max-width: 600px) {
    font-size: 18px;
  }
}

.successSubmitButton {
  margin-top: 32px;
}

.addressValidationModal .modalTitle,
.errorModal .modalTitle {
  align-self: stretch;
  margin-bottom: 16px;
  text-align: center;
  font-family: $font-default;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.4px;
}

.addressValidationModal .modalSubtitle,
.errorModal .modalSubtitle {
  align-self: stretch;
  text-align: center;
  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.addressValidationModal .modalTitle {
  font-size: 24px;
  text-align: left;
}

.addressValidationModal .modalSubtitle {
  text-align: left;
}

.errorModal :global(.modal-actions) {
  flex-direction: column;
  gap: 8px;
}

.errorModal :global(.modal-actions) :global(.button-main):first-of-type {
  display: flex;
  height: 55px;
  padding: 18px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  text-align: center;
  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.16px;

  border-radius: 100px;
  background: black;
  color: white;
}

.errorModal :global(.modal-actions) :global(.button-main):nth-of-type(2) {
  display: flex;
  height: 55px;
  padding: 18px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  text-align: center;
  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.16px;

  border-radius: 100px;
  background: transparent;
  color: black;
}

:global(.popup-content):has(.errorModal) {
  max-width: 330px;
}

.shippingAddress {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  text-align: left;
  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin-top: 16px;
  margin-bottom: 16px;
}

.shippingAddress > * {
  flex-grow: 0 !important;
}

.shippingAddressTitle {
  align-self: stretch;
  text-align: left;
  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.addressValidationButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 32px;
  gap: 1em;
}

.useThisAddressButton {
  display: flex;
  flex-grow: 1;
  height: 55px;
  padding: 18px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  text-align: center;
  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.16px;

  border-radius: 100px;
  background: black;
  color: white;
}

.editAddressButton {
  display: flex;
  flex-grow: 1;
  height: 55px;
  padding: 18px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  text-align: center;
  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.16px;

  border-radius: 100px;
  background: white;
  color: black;
  border: 1px solid #bebebe;
}
